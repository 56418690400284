"use strict";

const listItems = document.querySelectorAll(".filter__list");
const filterItem = document.querySelectorAll(".card");
const menuBtn = document.querySelector(".menu-icon");
const showMenu = document.querySelector(".drawer-menu");

// ポートフォリオギャラリーのフィルター機能
function toggleActiveClass(active) {
	listItems.forEach((item) => {
		item.classList.remove("active");
	});
	active.classList.add("active");
}

function toggleimages(dataClass) {
	if (dataClass === "all") {
		for (let i = 0; i < filterItem.length; i++) {
			filterItem[i].style.display = "block";
		}
	} else {
		for (let i = 0; i < filterItem.length; i++)
			filterItem[i].dataset.class === dataClass
				? (filterItem[i].style.display = "block")
				: (filterItem[i].style.display = "none");
	}
}

for (let i = 0; i < listItems.length; i++) {
	listItems[i].addEventListener("click", function () {
		toggleActiveClass(listItems[i]);
		toggleimages(listItems[i].dataset.class);
	});
}

// scroll Top
const PageTopBtn = document.getElementById("js-scroll-top");
PageTopBtn.addEventListener("click", () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
});

// drawer menu
menuBtn.addEventListener("click", function () {
	showMenu.classList.toggle("show");
});

// loading spinner
const bg = document.getElementById("loader-bg");
const loader = document.getElementById("loader");
/* ロード画面の非表示を解除 */
bg.classList.remove("is-hide");
loader.classList.remove("is-hide");

/* 読み込み完了 */
window.addEventListener("load", stopload);

/* 10秒で強制的にローディングを非表示 */
setTimeout("stopload()", 10000);

/* ロード画面を非表示にする処理 */
function stopload() {
	bg.classList.add("fadeout-bg");
	loader.classList.add("fadeout-loader");
}
